import CMSRender from "aldoo-ra/CMS/cms-render"
import Accordion from "aldoo-ra/Accordion"

const FAQ = () => {
  return (
    <div className="grid w-full mx-auto my-[100px] grid-cols-1 gap-3">
      <CMSRender
        //this is the type of content to render
        contentType="FAQ Section"
        renderItem={(data) => {
          return (
            <Accordion
              variant="aqua"
              items={data?.questions}
              title={data?.title}
            />
          )
        }}
      />
    </div>
  )
}

export default FAQ
