import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { motion } from "framer-motion"
import React, { useState } from "react"
import { VariantReader } from "aldoo-ra/VariantReader"
import Typography from "aldoo-ra/Typography"

const AccordionItem = ({
  question,
  answer,
  isOpen,
  onClick,
  variants = {},
}) => {
  const {
    item,
    question: questionClass,
    question_text,
    question_text_selected,
    toggle,
    motion_container,
    answer: answerClass,
  } = variants

  return (
    <div
      className={`${item} border-2 border-transparent hover:border-primary hover:border-2 ${
        isOpen ? "border-primary border-2" : ""
      }`}
    >
      <button
        onClick={onClick}
        className={`${questionClass} group w-full flex justify-between items-center`}
      >
        <span
          className={`${
            isOpen ? question_text_selected : question_text
          } group-hover:text-primary flex-grow pr-4`}
        >
          {question}
        </span>
        <div className="flex-shrink-0 flex items-center">
          <ChevronDownIcon
            className={`${toggle} w-6 h-6 transition-transform duration-300 ${
              isOpen ? "rotate-180" : ""
            } group-hover:text-primary`}
          />
        </div>
      </button>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className={motion_container}
      >
        <p className={answerClass}>{answer}</p>
      </motion.div>
    </div>
  )
}

const Accordion = ({ items, title, variant = "aqua", styleOverrides }) => {
  const [openIndex, setOpenIndex] = useState(null)

  // Get variant styles and merge with overrides
  const variants = VariantReader.useVariant({
    componentName: "Accordion",
    variant,
    styleOverrides,
  })

  const { container, title: titleClass } = variants

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className={container}>
      {title && <Typography className={titleClass}>{title}</Typography>}
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.title}
          answer={item.answer}
          isOpen={openIndex === index}
          onClick={() => handleAccordionClick(index)}
          variants={variants}
        />
      ))}
    </div>
  )
}

export default Accordion
